import { useQuery } from "react-query";
import AdditionalFile from "../dto/AdditionalFile";
import ColorType from "../enum/ColorType";
import httpService from "../services/Http.service";

export const usePayslipCount = (employeeId: string) => {
    return useQuery(
        ["payslip-count", employeeId],
        () =>
            httpService
                .get<number>(`file/count?employeeId=${employeeId}&type=1`)
                .then((res) => res.data),
        {
            enabled: !!employeeId && employeeId !== ""
        }
    );
};

const uploadAdditionalFile = async (file: File, t: any, toast: any) => {
    const formData = new FormData();
    formData.append("FileUpload_FormFile", file);
    return await httpService.uploadFile("file/upload", formData).catch((error: any) => {
        if (error instanceof Error && !handleFileSizeError(t, toast, error.message, file.name)) {
            if (toast?.push) {
                toast.push(
                    "Self Service Portal",
                    file.name + " " + t("general.fileIsEmpty"),
                    ColorType.Danger
                );
            }
        }
    });
};

export const processAdditionalFiles = async (
    additionalFiles: File[],
    t: any,
    toast: any,
    mainFileId: string
) => {
    let files: AdditionalFile[] = [];
    for (let i = 0; i < additionalFiles.length; ++i) {
        const response = await uploadAdditionalFile(additionalFiles[i], t, toast);
        if (response) {
            const data = response.data;
            files.push({ id: data, name: additionalFiles[i].name });
        } else {
            mainFileId !== "00000000-0000-0000-0000-000000000000"
                ? files.push({ id: mainFileId })
                : null;
            await deleteAdditionalFiles(files);
            files.length = 0;
            break;
        }
    }
    return files;
};

const deleteAdditionalFiles = (additionalFiles: AdditionalFile[]) => {
    additionalFiles.forEach((file) => {
        httpService.delete(`file?id=${file.id}`);
    });
};

export const handleFileSizeError = (
    t: any,
    toast: any,
    message: string,
    fileName: string
): boolean => {
    const regex = /(\d+(,\d+)?\s[KMG]?B)/;
    const match = message.match(regex);
    if (toast?.push && match) {
        toast.push(
            "Self Service Portal",
            t("general.fileSize", fileName, match[1]),
            ColorType.Danger
        );
        return true;
    }
    return false;
};
