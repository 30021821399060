import { useMutation, useQuery, useQueryClient } from "react-query";
import DocumentCommentDto from "../dto/DocumentComment";
import DocumentDto from "../dto/DocumentModel";
import DocumentApprovalDto from "../dto/DocumentApproval";
import DocumentDeclineDto from "../dto/DocumentDecline";
import CreateDocumentSubstitutesDto from "../dto/CreateDocumentSubstitutes";
import DocumentCancelDto from "../dto/CancelDocumentDto";
import httpService from "../services/Http.service";
import DocumentListModel from "../dto/DocumentListModel";

export const useDocumentForApprovalCount = (employeeId: string) => {
    return useQuery(
        ["document-needsapproval-count", employeeId],
        () =>
            httpService
                .get(`document/needsApproval/count?employeeId=${employeeId}`)
                .then((res) => res.data),
        {
            enabled: !!employeeId && employeeId !== ""
        }
    );
};

export const useDocumentForAcquaintanceCount = (employeeId: string) => {
    return useQuery(
        ["document-needsacquaintance-count", employeeId],
        () =>
            httpService
                .get(`document/needsAcquaintance/count?employeeId=${employeeId}`)
                .then((res) => res.data),
        {
            enabled: !!employeeId && employeeId !== ""
        }
    );
};

export const useDocumentComments = (documentId: string, commentCount: number) => {
    return useQuery<DocumentCommentDto[]>(["document-comments", documentId, commentCount], () =>
        httpService
            .get<
                DocumentCommentDto[]
            >(`document/comment?documentId=${documentId}&count=${commentCount}`)
            .then((res) => res.data)
    );
};

export const useCreateDocumentCommentMutation = (callback: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: DocumentCommentDto) =>
            httpService.post<DocumentCommentDto>("document/comment", data).then((res) => res.data),
        {
            onSettled: () => {
                queryClient.invalidateQueries("document-comments");
                callback();
            }
        }
    );
};

export const useDocument = (
    documentId: string,
    language: string,
    callback?: (data?: DocumentDto) => void
) => {
    const queryClient = useQueryClient();
    return useQuery(
        ["document", documentId, language],
        () =>
            httpService
                .get<DocumentDto>(`document?id=${documentId}&lang=${language}`)
                .then((res) => res.data),
        {
            onSuccess: callback,
            onSettled: () => {
                queryClient.invalidateQueries({
                    predicate: (query) => {
                        const queryKey: string = query.queryKey[0] as string;
                        return queryKey.includes("document-");
                    }
                });
            },
            staleTime: Infinity
        }
    );
};

export const useDocumentApprovalCheck = (employeeId: string, documentId: string) => {
    return useQuery<boolean>(
        ["document-needsapproval", employeeId, documentId],
        () =>
            httpService
                .get<boolean>(
                    `document/checkApproval?employeeId=${employeeId}&documentId=${documentId}`
                )
                .then((res) => res.data),
        {
            enabled: employeeId !== ""
        }
    );
};

export const useDocumentPdf = (
    documentId: string,
    language: string,
    onSuccessCallback?: () => void,
    onErrorCallback?: () => void,
    enabled?: boolean
) => {
    return useQuery<string>(
        ["document-pdf", documentId, language],
        () =>
            httpService
                .get<string>(`document/pdf?documentId=${documentId}&language=${language}`)
                .then((res) => res.data),
        {
            onSuccess: onSuccessCallback,
            onError: onErrorCallback,
            enabled: enabled,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            retry: false
        }
    );
};

export const useApproveDocumentMutation = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: DocumentApprovalDto) =>
            httpService
                .patch(
                    `document/approve?employeeId=${data.employeeId}&documentId=${data.documentId}`
                )
                .then((res) => res.data),
        {
            onSuccess: onSuccess || (() => queryClient.invalidateQueries("document"))
        }
    );
};

export const useDeclineDocumentMutation = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: DocumentDeclineDto) =>
            httpService
                .patch<DocumentDeclineDto>(
                    `document/decline?employeeId=${data.employeeId}&documentId=${data.documentId}&reason=${data.reason}`
                )
                .then((res) => res.data),
        {
            onSuccess: onSuccess || (() => queryClient.invalidateQueries("document"))
        }
    );
};

export const useCancelDocumentMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: DocumentCancelDto) =>
            httpService
                .patch<DocumentCancelDto>(
                    `document/cancel?documentId=${data.documentId}&userId=${data.employeeId}&reason=${data.reason}`
                )
                .then((res) => res.data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("document");
            }
        }
    );
};

export const useCreateDocumentSubstitutesMutation = (onSuccessCallback: () => void) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: CreateDocumentSubstitutesDto) =>
            httpService
                .post<CreateDocumentSubstitutesDto>(`document/substitutes`, data)
                .then((res) => res.data),
        {
            onSuccess: () => {
                onSuccessCallback();
                queryClient.invalidateQueries("document");
            }
        }
    );
};

export const useSubstitutionDocumentsCount = (employeeId: string) => {
    return useQuery(
        ["substitution-documents-count", employeeId],
        () =>
            httpService
                .get(`document/substitutionDocuments/count?employeeId=${employeeId}`)
                .then((res) => res.data),
        {
            enabled: employeeId !== ""
        }
    );
};

export const useDocumentListModel = (documentId: string, language: string) => {
    return useQuery(
        ["document-list-model", documentId, language],
        () =>
            httpService
                .get<DocumentListModel>(`document/listModel?id=${documentId}&lang=${language}`)
                .then((res) => res.data),
        {
            enabled: documentId !== "" && language !== ""
        }
    );
};
